app.navigationController = app.navigationController || {};
app.navigationController.defaultAction = {

    /**
     * Initialisation de la fonction
     * @returns {undefined}
     */

    init : function () {
        this.menu_acces_rapide();
        this.menu_mobile();
        this.btnsZoom();
    },

    menu_acces_rapide : function () {
        var menu = $('.acces-rapides');
        var buttonShow = menu.find('.buttonShow');
        var buttonHide = menu.find('.buttonHide');
        var nav = menu.find('#menu-menu-acces-rapides');
        var hNav = nav.height();

        buttonShow.on('click', function() {
            if(!$(this).hasClass('active')){
                nav.css('margin-top', '0px');
                $(this).toggleClass('active');
                buttonHide.toggleClass('active');
            }
        });

        buttonHide.on('click', function() {
            if(!$(this).hasClass('active')){
                hNav = nav.height();
                nav.css('margin-top', '-' + hNav + 'px');
                $(this).toggleClass('active');
                buttonShow.toggleClass('active');
            }
        });

        $( window ).resize(function() {
            if(buttonHide.hasClass('active')){
                hNav = nav.height();
                nav.css('margin-top', '-' + hNav + 'px');
            }
        });

        if ( window.matchMedia("(max-width: 768px)").matches ) {
            hNav = nav.height();
            nav.css('margin-top', '-' + hNav + 'px');
            buttonShow.removeClass('active');
            buttonHide.addClass('active');
        }

        $( window ).resize(function() {
            if ( window.matchMedia("(max-width: 768px)").matches ) {
                hNav = nav.height();
                nav.css('margin-top', '-' + hNav + 'px');
                buttonShow.removeClass('active');
                buttonHide.addClass('active');
            }
        });
    },

    menu_mobile : function () {
        var hamburger = $('.hamburger');
        //var menu = $('.headerNav');
        var menu = $('.headerNav > ul');

        if ( window.matchMedia("(max-width: 961px)").matches ) {
            var hMenu = menu.height() + 60;
            menu.css('margin-top', '-' + hMenu + 'px');
            menu.css('transition', 'margin-top .5s ease');
        }

        hamburger.on('click', function () {
            $(this).toggleClass('is-active');
            hMenu = menu.height() + 60;

            if (menu.hasClass('active')) {
                menu.css('margin-top', '-' + hMenu + 'px');
            } else {
                menu.css('margin-top', 0);
            }

            menu.toggleClass('active');
        });

        $( window ).resize(function() {
            if ( window.matchMedia("(min-width: 961px)").matches ) {
                menu.css('margin-top', 0);
            } else {
                if (!menu.hasClass('active')) {
                    var hMenu = menu.height() + 60;
                    menu.css('margin-top', '-' + hMenu + 'px');
                }
            }
        });
    },
    
    btnsZoom : function () {
        var btnZoomUp = $('.btnZoomUp');
        var btnZoomDown = $('.btnZoomDown');
        var blkHtml = $('html');

        btnZoomUp.on('click', function () {
            var zoom = $(this).data('zoom');
            zoom = zoom + 0.1;

            if (zoom <= 1.5) {
                blkHtml.attr('style', '-moz-transform: scale('+ zoom +'); -ms-transform: scale('+ zoom +'); -webkit-transform: scale('+ zoom +'); -o-transform: scale('+ zoom +'); transform: scale('+ zoom +');');
                btnZoomUp.data('zoom', zoom);
                btnZoomDown.data('zoom', zoom);
            }
        });

        btnZoomDown.on('click', function () {
            var zoom = $(this).data('zoom');
            zoom = zoom - 0.1;

            if (zoom >= 0.5) {
                blkHtml.attr('style', '-moz-transform: scale('+ zoom +'); -ms-transform: scale('+ zoom +'); -webkit-transform: scale('+ zoom +'); -o-transform: scale('+ zoom +'); transform: scale('+ zoom +');');
                btnZoomDown.data('zoom', zoom);
                btnZoomUp.data('zoom', zoom);
            }
        });
    }

};
