app.pageController = app.pageController || {};
app.pageController.defaultAction = {

    /**
     * Initialisation de la fonction
     * @returns {undefined}
     */

    init : function () {
        if ($(".colRight")[0]) {
            this.sticky_col();
            this.topReturn();
        }
        this.addMaps();
    },

    sticky_col : function () {
        var colRight = $('.colRight');
        var siteContent = $('.siteMainContent');
        var posSiteContent = siteContent.position().top;

        $( window ).scroll(function() {
            var hCol = Math.round($('.colRight .focus').height() + $('.colRight .infos-pratiques').height());

            if ($(window).width() > 900 && siteContent.height() != (hCol + 60)) {

                var hWin = Math.round($(window).height());

                if (hWin > (hCol + 90)) {
                    var posTop = Math.round($(document).scrollTop());

                    if (posTop >= (posSiteContent - 30)) {
                        var top = (posTop - posSiteContent + 30);
                        var right = ($(window).width() - siteContent.width()) / 2;
                        var bottomContent = Math.round(siteContent.position().top + siteContent.height());
                        var bottomCol = Math.round(hCol + posTop + 90);

                        if (bottomCol >= bottomContent) {
                            colRight.attr('style', 'position:absolute;right:20px;bottom:0px;');
                        } else {
                            colRight.attr('style', 'position:fixed;right:' + right + 'px;top:30px;');
                        }
                    } else {
                        colRight.removeAttr('style');
                    }
                } else {
                    colRight.removeAttr('style');
                }
            }
        });

        $( window ).resize(function() {
            if ($(window).width() > 900) {
                var right = ($(window).width() - siteContent.width()) / 2;

                if (colRight.css('position') == 'fixed') {
                    colRight.attr('style', 'position:fixed;right:' + right + 'px;top:30px;');
                }
            }
        });
    },

    topReturn : function () {
        $('.btn-return .up').click(function(e){
            e.preventDefault();

            $('html, body').animate({
                scrollTop: 0
            }, 'slow');

            return false;
        });
    },
    addMaps : function () {
        var tileSourceURL = 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png';
        var tileSourceOptions = {
          attribution: '&copy; Openstreetmap France | &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        };
        var myIcon = L.icon({
          iconUrl: '/content/themes/chi-elbeuf/img/marker.png',
          iconSize: [40, 52],
          iconAnchor: [20, 52],
        });

        // Map 1
        var mymap1 = L.map('map1', {
          center: [49.305413, 1.040397],
          zoom: 15,
          scrollWheelZoom: false
        });
      
        L.tileLayer(tileSourceURL, tileSourceOptions).addTo(mymap1);

        var marker = new L.marker([49.305413, 1.040397], {
          icon: myIcon
        }).addTo(mymap1);
        marker
        .bindPopup('<p>CHIELVR - SITE D’ELBEUF (Les Feugrais)</p>');

        // Map 2
        var mymap2 = L.map('map2', {
          center: [49.210858, 1.175510],
          zoom: 15,
          scrollWheelZoom: false
        });
      
      L.tileLayer(tileSourceURL, tileSourceOptions).addTo(mymap2);
        var marker = new L.marker([49.210858, 1.175510], {
          icon: myIcon
        }).addTo(mymap2);
        marker
        .bindPopup('<p>CHIELVR - SITE DE LOUVIERS</p>');

        // Map 3
        var mymap3 = L.map('map3', {
          center: [49.305392, 1.040397],
          zoom: 15,
          scrollWheelZoom: false
        });
      
      L.tileLayer(tileSourceURL, tileSourceOptions).addTo(mymap3);
        var marker = new L.marker([49.305392, 1.040397], {
          icon: myIcon
        }).addTo(mymap3);
        marker
        .bindPopup('<p>Résidence Les collines de la Seine</p>');
        
        // Map 4
        var mymap4 = L.map('map4', {
          center: [49.290588, 0.999878],
          zoom: 15,
          scrollWheelZoom: false
        });
      
      L.tileLayer(tileSourceURL, tileSourceOptions).addTo(mymap4);
        var marker = new L.marker([49.290588, 0.999878], {
          icon: myIcon
        }).addTo(mymap4);
        marker
        .bindPopup('<p>Résidence La Source</p>');
        
        // Map 5
        var mymap5 = L.map('map5', {
          center: [49.306734, 1.032541],
          zoom: 15,
          scrollWheelZoom: false
        });
      
      L.tileLayer(tileSourceURL, tileSourceOptions).addTo(mymap5);
        var marker = new L.marker([49.306734, 1.032541], {
          icon: myIcon
        }).addTo(mymap5);
        marker
        .bindPopup('<p>Résidence Le Bois Rond</p>');
        
        // Map 6
        var mymap6 = L.map('map6', {
          center: [49.210837, 1.175521],
          zoom: 15,
          scrollWheelZoom: false
        });
      
      L.tileLayer(tileSourceURL, tileSourceOptions).addTo(mymap6);
        var marker = new L.marker([49.210837, 1.175521], {
          icon: myIcon
        }).addTo(mymap6);
        marker
        .bindPopup('<p>Résidence Saint-Taurin</p>');
        
  }

};
